export const MAX_QUESTION_COUNT = 5;

// Below are the constants used for displaying different types of agreements in the copilot spalsh screen
export const SEULA_TEXT = 'Supplemental End User License Agreement';
// export const SEULA_PDF_LINK = "https://www.paloaltonetworks.com/content/dam/pan/en_US/assets/pdf/legal/supplemental-end-user-license-agreement-for-co-pilots.pdf"
export const SEULA_LINK =
  'https://www.paloaltonetworks.com/content/dam/pan/en_US/assets/pdf/legal/supplemental-end-user-license-agreement-for-co-pilots.pdf';

export const ACCEPTANCE_TEXT =
  'By clicking on "Accept", I have read and agree to the “Supplemental End User License Agreement - Copilot” and agree that Copilot inputs and outputs may be processed in other regions.';

export const PENDO_EVENT_MAX_SIZE = 350;

export const GENERIC_LOADING_TEXT = [
  'Processing your request',
  'Thinking... just a moment',
  'Understanding input...almost there',
  'Just a moment...compiling the information',
  'Analyzing the data',
  'Gathering insights',
  'Brainstorming the best response',
  'Preparing your response',
  'Working diligently...stay tuned',
  'Generating... Stay tuned',
];

export const FEDRAMP_REGION = 'gov';

export const COPILOT_V2_FF = 'sase.copilot.v2';
