/* istanbul ignore file */

export enum APP_VIEW_MODES {
  EXPANDED = 'EXPANDED',
  SLIM = 'SLIM',
}

export enum APP_THEMES {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum APP_MODES {
  DEFAULT_MODE = 'customer-copilot-mode',
  CSP_MODE = 'csp-mode',
  TSE_ASSISTANT_MODE = 'tse-assistant-mode',
}

export enum REMINDER_TEXT_MSGS {
  HOW_WAS_THIS_RESPONSE = 'How was this response?',
  YOUR_FEEDBACK_MATTERS = 'Your feedback helps Copilot improve!',
  TAKE_A_MOMENT = 'Take a moment to rate this response for better future interactions.',
}

export enum MESSAGES {
  READY = 'ready',
  START_CONVERSATION = 'start_conversation',
  ISSUE_RESOLVED = 'issue_resolved',
  ISSUE_RESOLVED_SUBMIT = 'issue_resolved_submit',
  PROCEED_TO_CREATE_CASE = 'proceed_to_create_case',
  PROCEED_TO_CREATE_CASE_SUBMIT = 'proceed_to_create_case_submit',
  CASE_CREATED = 'case_created',
  HIDE_COPILOT = 'hide_copilot',
  REFERENCE_DOC_LINK_CLICKED = 'reference_doc_link_click',
  EMPTY_LLM_RESPONSE = 'empty_LLM_response',
  LLM_ERROR_RESPONSE = 'LLM_error_response',
}

export enum RESPONSE_TYPES {
  TEXT = 'TEXT',
  ARRAY = 'ARRAY',
  ANY = 'ANY',
}

export enum SERVICE_IDS {
  CLOUD_MANAGEMENT_SERVICE_ID = 12,
}

export const DEVICE_QUARANTINE_PARAMS = ['fw_device_id'];

export const SPECIAL_CHARACTERS_REGEX = /[.*+?^${}()|[\]\\]/g;

export const CURLY_BRACES_REGEX = /\{([^}]+)\}/;

export const CLOUD_MANAGEMENT_XPATH =
  "/config/devices/entry[@name='localhost.localdomain']/snippet/entry[@name='app-tagging']/rulebase/security/rules/entry[@name='__noname__']/application/member";
